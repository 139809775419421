// 
// Extras pages.scss
//


// pricing


.plan-box{
    .plan-btn{
        position: relative;

        &::before{
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            background: $gray-300;
            left: 0px;
            right: 0px;
            top: 12px;
        }
    }
}

/* directory */
.social-links {
  li {
    a {
      margin-bottom: 6px;
      border-radius: 50%;
      display: inline-block;
      height: 30px;
      line-height: 30px;
      width: 30px;
      text-align: center;
    }
  }
}




// FAQ


.faq{
  position: relative;
  .card-header{
      border:none;
      background: transparent;
      padding: 22px 20px;
  }
} 

.accordion{
  .card{
      border-bottom: 1px solid $gray-200 !important;
      border-radius: 4px !important;
      box-shadow: none;
  }
}

a.faq[data-toggle=collapse].collapsed:before {
  content: '\F419';
}

a.faq[data-toggle=collapse]:before {
  content: '\F377';
  display: block;
  font-family: 'Material Design Icons';
  font-size: 20px;
  color: $text-muted;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
